
import CloudFun, { Condition, defineComponent, Operator, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'

export default defineComponent({
  components: {
    Grid,
    SelectBox
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]

    const user = CloudFun.current?.user;
    const policy = CloudFun.current?.policy;

    const checkPermissions = (key: string) => {
      // console.log(user?.Permissions)
      try {
        return user && policy?.isApproved(user, key);
      } catch {
        return false;
      }
    };

    const canCreate = checkPermissions("DepartmentCreate");
    const canUpdate = checkPermissions("DepartmentUpdate");
    const canDelete = checkPermissions("DepartmentDelete");

    const gridOptions: GridOptions = {
      stripe: false,
      title: '部門管理',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      canCreate: canCreate,
      canUpdate: canUpdate,
      canDelete: canDelete,
      multiselect: false,
      printConfig: { sheetName: '部門管理清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '部門管理清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        {
          field: 'Name',
          title: '名稱',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: false,
        },
        {
          field: 'ContactPerson',
          title: '聯絡人',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: false,
        },
      ],
      promises: {
        query: model ? (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          return model.dispatch('department/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('department/query') : undefined,
        save: model ? (params) => model.dispatch('department/save', params) : undefined
      },
      modalConfig: { width: "70%", showFooter: true },
      // treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: 'right',
      items: [
        {
          field: 'Name',
          title: '名稱',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Number',
          title: '編號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入編號', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Email',
          title: '服務信箱',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入服務信箱', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'ContactPerson',
          title: '聯絡人',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '聯絡人', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'ContactPhone',
          title: '聯絡電話',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入聯絡電話', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'SupervisorId',
          title: '負責人',
          span: 12,
          slots: { default: "column-supervisorId" }
        },
      ],
      rules: {
        Name: [{ type: 'string', required: true }],
        Email: [{ required: true, message: "Email格式錯誤", pattern: new RegExp("^\\w+((-\\w+)|(\\.\\w+))*\\@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z]+$") }],
        ContactPhone: [
          {
            type: "string",
            pattern: new RegExp(
              "^([\\+][0-9]{1,3}([ \\.\\-])?)?([\\(][0-9]{1,6}[\\)])?([0-9 \\.\\-|#]{1,32})(([A-Za-z \\:]{1,11})?[0-9]{1,4}?)$"
            ),
          },
        ],
      }
    }

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Person.Name",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: (value: any) => model!.dispatch("employee/find", value), // eslint-disable-line
        query: (params: any) => {
          params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          params.sortings!.push({ column: 'CreatedTime', order: 1 });
          return model!.dispatch("employee/query", params) // eslint-disable-line
        }
      }
    };

    const initData = (row: any, callback: any) => {
      callback();
    }

    return {
      grid,
      gridOptions,
      formOptions,
      model,
      selectorOptions,
      initData,
      canCreate,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
